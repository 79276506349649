import './Software.css'
import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import Button from '@mui/material/Button'
import RequestForm from '../../../components/RequestForm/RequestForm'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import DeliverInfo from '../../../components/DeliverInfo/DeliverInfo'

function Software() {
    const [isRequestModalOpen, setRequestModalOpen] = useState(false)

    const openRequestModal = () => {
        setRequestModalOpen(true)
    }

    const closeRequestModal = () => {
        setRequestModalOpen(false)
    }

    return (
        <div className="Software">
            { isRequestModalOpen && 
                <div className="RequestModal">
                    <RequestForm close={() => closeRequestModal()}/>
                </div>
            }
            <Header/>
                <div className="Banner">
                    <video autoPlay loop muted playsInline src="https://assets.mixkit.co/videos/preview/mixkit-a-software-developer-working-and-drinking-coffee-1749-large.mp4"></video>
                    <div className="BannerTitle">We are masters of software.<br/>With us, possibilities are limitless.</div>
                    <div className="BannerSubtitle">Unlock the boundless potential of software, your ultimate versatile tool. Empowering you to conquer a myriad of challenges, our solutions are engineered to be efficient, scalable, and adaptable. Committed to sustainability, we craft software that not only solves problems but also embraces responsibility, shaping a better future.</div>
                    <Button 
                        id="SoftwareBannerBtn"
                        onClick={() => openRequestModal()}
                        variant="outlined"
                    >
                        Send Request
                    </Button>
                </div>
                <div className='AboutSoftware'>
                    <div className="AboutTitle">Technologies of Welcome NoCode.</div>
                    <div className="AboutSubtitle">Exploring the Future with AI, IoT, Blockchain, Robotics, and More.</div>
                    <Tabs
                        aria-label="Vertical tabs"
                        orientation="vertical"
                        sx={{ width: '70%', borderRadius: 'lg' }}
                    >
                        <TabList>
                            <Tab>Web development</Tab>
                            <Tab>Mobile development</Tab>
                            <Tab>Cloud computing</Tab>
                            <Tab>Artificial intelligence</Tab>
                            <Tab>Internet of things</Tab>
                            <Tab>Machine learning</Tab>
                            <Tab>Big data</Tab>
                            <Tab>Blockchain</Tab>
                            <Tab>Robotics</Tab>
                        </TabList>
                        <TabPanel sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we pride ourselves on being industry leaders in web development, specializing in creating innovative solutions for Customer Relationship Management (CRM) systems and Software-as-a-Service (SaaS) projects. With our extensive experience and expertise, we have helped numerous businesses streamline their operations, enhance customer experiences, and drive sustainable growth. In this blog post, we'll delve into how our web development services can empower businesses like yours.<br/> <br/> <br/>
                            A robust CRM system is the backbone of any successful business. It enables you to manage customer interactions, track sales pipelines, and improve overall customer satisfaction. Our team of skilled developers has hands-on experience in building custom CRM solutions tailored to the unique needs of your organization. From lead generation and customer profiling to sales automation and support ticketing, our CRM systems are designed to optimize your customer relationship management processes, resulting in increased efficiency and improved revenue generation.<br/> <br/> <br/>
                            In today's digital landscape, SaaS projects have gained immense popularity, offering businesses the flexibility and scalability they need to grow rapidly. Whether you're launching a new SaaS product or seeking to upgrade your existing one, our web development expertise can help bring your vision to life. We specialize in creating secure, user-friendly, and scalable SaaS applications that cater to a wide range of industries. By leveraging cutting-edge technologies and following industry best practices, we ensure that your SaaS project is primed for success, allowing you to focus on driving user adoption and achieving sustainable business growth.<br/> <br/> <br/>
                            <b>Our Approach to Web Development:</b><br/>
                            We understand that every business is unique, with its own set of challenges and objectives. That's why our web development process begins with a thorough analysis of your specific requirements. Our team works closely with you to gain a deep understanding of your business processes, target audience, and long-term goals. Based on this understanding, we craft a comprehensive development strategy that encompasses design, functionality, security, and scalability. Throughout the development process, we maintain open lines of communication, providing regular updates and seeking your feedback to ensure the final product aligns perfectly with your vision.
                        </TabPanel>
                        <TabPanel value={1} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we excel in mobile development, specializing in creating innovative solutions for both iOS and Android platforms. With our expertise in crafting seamless mobile applications, we have helped numerous businesses elevate their digital presence, engage customers, and unlock new opportunities. In this blog post, we will delve into how our mobile development services can empower businesses like yours.<br/> <br/> <br/>
                            In today's fast-paced world, mobile devices have become an integral part of our lives. Businesses that embrace mobile development gain a competitive edge by reaching their target audience anytime, anywhere. Our talented team of mobile developers possesses extensive experience in building robust, user-friendly, and feature-rich applications for both iOS and Android platforms. Whether it's developing a captivating customer-facing app, optimizing internal processes, or creating a mobile-first product, we leverage the latest technologies to bring your mobile vision to life.<br/> <br/> <br/>
                            <b>iOS Development:</b><br/>With the growing popularity of Apple devices, having a strong presence on the iOS platform is essential for businesses looking to engage with their customers effectively. Our iOS development team excels in creating elegant and intuitive applications that deliver exceptional user experiences. From concept to deployment, we focus on leveraging the latest iOS features and design principles to ensure your app stands out in the Apple ecosystem, driving user adoption and enhancing brand loyalty.<br/> <br/> <br/>
                            <b>Android Development:</b><br/>Android devices dominate the global market, making it crucial for businesses to have a robust Android application strategy. Our Android development experts possess in-depth knowledge of the Android ecosystem and leverage their skills to create captivating applications tailored to your business needs. We develop scalable and user-centric Android apps that not only expand your reach but also maximize your market share, driving business growth and enabling you to connect with a vast user base.<br/><br/><br/>
                            <b>Our Approach to Mobile Development:</b><br/>
                            At Welcome NoCode, we understand that every business has unique goals and requirements. Our mobile development process begins with a comprehensive analysis of your specific needs, target audience, and industry landscape. We collaborate closely with you to define the app's functionalities, design aesthetics, and user experience, ensuring that it aligns seamlessly with your brand identity. Throughout the development cycle, we prioritize regular communication, providing updates, soliciting feedback, and conducting thorough testing to ensure the final product meets your expectations.
                        </TabPanel>
                        <TabPanel value={2} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we specialize in harnessing the immense potential of cloud computing by building innovative solutions on leading platforms such as AWS, Azure, and GCP. With our expertise in cloud architecture and implementation, we have helped businesses transform their operations, increase scalability, and drive digital innovation. In this blog post, we will explore how our cloud computing services can empower your organization.<br/> <br/> <br/>
                            Cloud computing has revolutionized the way businesses operate, offering unparalleled scalability, flexibility, and cost-efficiency. Our team of cloud experts is well-versed in the intricacies of AWS (Amazon Web Services), Azure, and GCP (Google Cloud Platform), enabling us to tailor cloud solutions that align with your specific business needs. Whether you require infrastructure migration, application development, or data storage and analytics, we leverage the power of these cloud platforms to deliver scalable, reliable, and secure solutions.<br/> <br/> <br/>
                            <b>AWS</b> Scalable Solutions for the Cloud Pioneer:<br/><br/>
                            As the industry leader in cloud computing, AWS provides a comprehensive suite of services that enable businesses to build and deploy applications at scale. Our AWS-certified team possesses deep expertise in leveraging the full potential of AWS services, including EC2, S3, Lambda, and DynamoDB, to design robust and cost-effective cloud solutions. We work closely with you to understand your requirements, architect a scalable infrastructure, and migrate your existing systems seamlessly to the AWS cloud, empowering your business to drive efficiency, innovation, and growth.<br/><br/><br/>
                            <b>Azure</b> Unleashing the Power of Microsoft's Cloud Platform:<br/><br/>
                            Azure offers a versatile and comprehensive set of cloud services, enabling businesses to build, deploy, and manage applications seamlessly. Our Azure specialists possess in-depth knowledge of Microsoft's cloud platform, allowing us to develop tailored solutions that leverage services such as Azure Virtual Machines, Azure App Service, and Azure Cosmos DB. Whether you're looking to modernize legacy applications, create intelligent data-driven solutions, or ensure robust security and compliance, our expertise in Azure empowers your business to tap into the power of the cloud with confidence.<br/><br/><br/>
                            <b>GCP</b> Driving Innovation with Google's Cloud Infrastructure:<br/><br/>
                            Google Cloud Platform (GCP) provides a cutting-edge suite of cloud services that enable businesses to leverage Google's vast infrastructure and innovation capabilities. Our team of GCP experts harnesses the power of services such as Compute Engine, Cloud Storage, BigQuery, and AI/ML tools to architect scalable and intelligent solutions. Whether you're looking to optimize data analytics, leverage machine learning algorithms, or build reliable and globally distributed applications, our proficiency in GCP helps your business unlock new opportunities and drive digital transformation.<br/><br/><br/>
                            <b>Our Approach to Cloud Computing:</b><br/><br/>
                            At Welcome NoCode, we understand that each business has unique goals, infrastructure, and compliance requirements. Our cloud computing approach begins with a thorough analysis of your current systems, objectives, and challenges. We collaborate closely with your team to develop a tailored cloud strategy that maximizes the potential of AWS, Azure, or GCP, ensuring optimal scalability, security, and cost-efficiency. Throughout the implementation process, we prioritize clear communication, regular updates, and seamless integration, allowing your business to embrace cloud computing with minimal disruption.
                        </TabPanel>
                        <TabPanel value={3} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we are at the forefront of harnessing the power of Artificial Intelligence (AI) to drive digital transformation and innovation across industries. Our expertise in AI development enables us to build intelligent solutions that automate processes, extract valuable insights, and deliver enhanced customer experiences. In this blog post, we will explore how our AI services can empower your organization and revolutionize the way you operate.<br/> <br/> <br/>
                            Artificial Intelligence is revolutionizing industries by empowering businesses to automate tasks, gain deeper insights from data, and make informed decisions. Our team of AI experts combines cutting-edge technologies such as machine learning, natural language processing, and computer vision to develop intelligent solutions tailored to your specific requirements. Whether you need AI-powered chatbots, predictive analytics models, or computer vision applications, we leverage AI to drive efficiency, innovation, and competitive advantage for your organization.<br/> <br/> <br/>
                            <b>Natural Language Processing</b> Enhancing Human-Machine Interactions:<br/><br/>
                            Natural Language Processing (NLP) enables machines to understand and interpret human language, facilitating seamless interactions between humans and AI systems. Our NLP solutions encompass chatbots, sentiment analysis, text summarization, and language translation, among others. By integrating NLP capabilities into your applications, we empower your business to deliver personalized customer experiences, automate customer support, and extract meaningful insights from unstructured text data.<br/><br/><br/>
                            <b>Computer Vision</b> Enabling Visual Intelligence:<br/><br/>
                            Computer Vision is a field of AI that focuses on training machines to understand and interpret visual information. Our computer vision solutions leverage advanced algorithms to analyze images and videos, enabling object recognition, image classification, and facial recognition. Whether you need intelligent surveillance systems, quality control automation, or augmented reality applications, our expertise in computer vision empowers your organization to unlock visual intelligence and streamline various processes.<br/><br/><br/>
                            <b>Our Approach to Artificial Intelligence:</b><br/><br/>
                            At Welcome NoCode, we understand that each business has unique AI requirements and challenges. Our AI development process begins with a comprehensive analysis of your business objectives, data availability, and industry landscape. We collaborate closely with your team to define the AI solution's functionalities, data requirements, and desired outcomes. Throughout the development cycle, we emphasize continuous communication, regular progress updates, and rigorous testing to ensure that the AI solution meets your expectations and delivers tangible results.
                        </TabPanel>
                        <TabPanel value={4} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we are passionate about harnessing the transformative power of the Internet of Things (IoT) to create innovative solutions that connect devices, collect data, and unlock new possibilities. Our expertise in IoT development enables us to build smart and connected systems that optimize processes, enhance efficiency, and deliver seamless experiences. In this blog post, we will explore how our IoT services can empower your organization and shape the future of connected technologies.<br/><br/><br/>
                            The Internet of Things revolutionizes industries by connecting devices and enabling them to communicate, share data, and collaborate seamlessly. Our team of IoT experts combines cutting-edge technologies, including sensors, connectivity solutions, and data analytics, to develop intelligent and connected systems tailored to your specific needs. Whether you need smart home automation, industrial monitoring and control, or asset tracking solutions, we leverage the power of IoT to transform your organization.<br/><br/><br/>
                            <b>Connected Devices</b> Enabling Real-time Data Insights:<br/><br/>
                            Connected devices are the backbone of IoT solutions, enabling the collection and transmission of valuable data. Our IoT development services encompass designing and deploying devices that are equipped with sensors, embedded systems, and wireless connectivity, allowing for real-time data monitoring and analysis. By leveraging this data, businesses can gain valuable insights, make informed decisions, and optimize processes to drive efficiency and productivity.<br/><br/><br/>
                            <b>Data Analytics</b> Extracting Actionable Insights:<br/><br/>
                            The vast amount of data generated by IoT devices holds tremendous potential for businesses. Our IoT solutions incorporate advanced data analytics techniques, including machine learning and predictive analytics, to extract actionable insights from IoT data. By analyzing patterns, trends, and anomalies, we help businesses make data-driven decisions, improve operational efficiency, and unlock new revenue streams. Whether it's optimizing supply chain logistics, predicting maintenance needs, or enhancing customer experiences, our data analytics capabilities empower your organization to derive maximum value from IoT data.<br/><br/><br/>
                            <b>Connectivity and Security</b> Enabling Reliable and Secure Communication:<br/><br/>
                            Reliable connectivity and robust security are critical aspects of IoT solutions. Our IoT development process includes selecting and implementing appropriate connectivity protocols, such as Wi-Fi, Bluetooth, or cellular networks, to ensure seamless communication between devices. We also prioritize security measures, including encryption, authentication, and secure data transmission, to safeguard your IoT ecosystem from potential vulnerabilities and protect sensitive information.<br/><br/><br/>
                            <b>Our Approach to IoT Development:</b><br/><br/>
                            At Welcome NoCode, we understand that each business has unique IoT requirements and objectives. Our IoT development process begins with a thorough understanding of your specific needs, operational challenges, and desired outcomes. We collaborate closely with your team to design a comprehensive IoT strategy that includes device selection, connectivity options, data analytics, and security considerations. Throughout the development cycle, we emphasize regular communication, iterative development, and rigorous testing to deliver a robust and tailored IoT solution that meets your expectations.
                        </TabPanel>
                        <TabPanel value={5} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we are passionate about harnessing the immense potential of Machine Learning (ML) to build intelligent solutions that drive innovation, enhance decision-making, and transform industries. Our expertise in ML development enables us to create predictive models, automate processes, and extract valuable insights from complex datasets. In this blog post, we will explore how our ML services can empower your organization and unlock new horizons of success.<br/><br/><br/>
                            <b>Machine Learning</b> Unleashing the Power of Data:<br/><br/>
                            Machine Learning is a subset of Artificial Intelligence that focuses on training models to learn from data and make accurate predictions or decisions without being explicitly programmed. Our team of ML experts leverages advanced algorithms, statistical modeling techniques, and powerful computing capabilities to develop intelligent solutions tailored to your specific business needs. Whether it's predictive analytics, anomaly detection, or recommendation systems, we unlock the power of your data to deliver tangible results.<br/><br/><br/>
                            <b>Predictive Analytics</b> Making Informed Decisions:<br/><br/>
                            Predictive analytics is a key application of ML that enables businesses to forecast future outcomes based on historical data. Our ML solutions leverage predictive models to help you make informed decisions, optimize processes, and improve business performance. By analyzing patterns, trends, and relationships within your data, we enable you to anticipate customer behavior, optimize inventory management, and make accurate sales forecasts, among other applications. The power of predictive analytics lies in its ability to drive data-driven decision-making, leading to enhanced operational efficiency and competitive advantage.<br/><br/><br/>
                            <b>Automation</b> Enhancing Efficiency and Productivity:<br/><br/>
                            ML-powered automation is revolutionizing industries by streamlining processes, reducing manual intervention, and enhancing efficiency. Our ML solutions enable businesses to automate repetitive tasks, such as data entry, image recognition, or natural language processing, freeing up valuable time for your workforce to focus on higher-value activities. By leveraging ML algorithms, we help you build intelligent systems that learn from data, adapt to new patterns, and continuously improve over time, driving productivity gains and unleashing the full potential of your organization.<br/><br/><br/>
                            <b>Insights from Data</b> Extracting Actionable Knowledge:<br/><br/>
                            The abundance of data generated by businesses holds valuable insights waiting to be discovered. Our ML expertise extends to developing solutions that extract actionable knowledge from complex datasets. By utilizing techniques such as clustering, classification, and regression, we help you gain deeper insights, uncover hidden patterns, and understand customer behavior better. These insights can fuel strategic decision-making, enable personalized marketing campaigns, and guide product development, propelling your business forward in the era of data-driven innovation.<br/><br/><br/>
                            <b>Our Approach to Machine Learning:</b><br/><br/>
                            At [Company Name], we understand that every business has unique requirements and objectives. Our ML development process starts with a thorough understanding of your business challenges, data availability, and desired outcomes. We collaborate closely with your team to define the ML solution's objectives, identify relevant data sources, and select the most appropriate algorithms and techniques. Throughout the development cycle, we emphasize iterative development, rigorous testing, and constant communication to ensure that the ML solution meets your expectations and delivers tangible value.<br/><br/><br/> 
                        </TabPanel>
                        <TabPanel value={6} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we are passionate about harnessing the transformative power of Big Data to build intelligent and data-driven solutions that drive business growth, enhance decision-making, and unlock hidden opportunities. With our expertise in Big Data analytics and technologies, we enable organizations to extract valuable insights, optimize operations, and gain a competitive edge. In this blog post, we will explore how our Big Data services can empower your organization and unleash the full potential of your data.<br/><br/><br/>

                            <b>Big Data</b> Empowering Data-Driven Decisions:<br/><br/>
                            Big Data refers to large and complex datasets that cannot be easily processed using traditional data processing methods. Our team of Big Data experts specializes in managing, processing, and analyzing vast amounts of data to uncover patterns, trends, and correlations. By harnessing the power of Big Data, we enable businesses to make informed decisions, drive innovation, and unlock new revenue streams.<br/><br/><br/>

                            <b>Data Management</b> Unleashing the Full Potential of Data:<br/><br/>
                            Effective data management is crucial for maximizing the value of Big Data. Our Big Data solutions encompass data integration, cleansing, and storage strategies to ensure data quality and accessibility. We leverage scalable and distributed data processing frameworks, such as Hadoop and Apache Spark, to handle the volume, velocity, and variety of Big Data. By implementing robust data management practices, we help you unleash the full potential of your data and enable data-driven decision-making across your organization.<br/><br/><br/>

                            <b>Data Analytics</b> Extracting Actionable Insights:<br/><br/>
                            The true value of Big Data lies in the insights it can provide. Our data analytics capabilities empower organizations to extract actionable insights from complex and diverse datasets. By leveraging advanced analytics techniques, including machine learning, statistical analysis, and data visualization, we help you uncover hidden patterns, identify trends, and gain a deeper understanding of your business operations, customers, and market dynamics. These insights enable you to optimize processes, personalize experiences, and drive innovation across your organization.<br/><br/><br/>

                            <b>Real-time Analytics</b> Making Agile Decisions:<br/><br/>
                            In today's fast-paced business environment, the ability to analyze and act upon data in real time is critical. Our Big Data solutions incorporate real-time analytics capabilities that enable organizations to monitor data streams, detect anomalies, and make agile decisions. By leveraging technologies such as Apache Kafka and stream processing frameworks, we help you process and analyze data as it flows, empowering you to respond swiftly to emerging trends, threats, and opportunities.<br/><br/><br/>

                            <b>Our Approach to Big Data:</b><br/><br/>
                            At Welcome NoCode, we understand that each organization has unique Big Data requirements and challenges. Our Big Data development process starts with a comprehensive analysis of your data landscape, business objectives, and desired outcomes. We work closely with your team to identify relevant data sources, define data processing and analytics goals, and select the appropriate tools and technologies. Throughout the development cycle, we emphasize iterative development, constant communication, and rigorous testing to ensure that our Big Data solution meets your expectations and delivers tangible value.<br/><br/><br/>
                        </TabPanel>
                        <TabPanel value={7} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we are passionate about harnessing the transformative power of blockchain technology to build transparent, secure, and decentralized solutions that revolutionize industries. With our expertise in blockchain development, we enable organizations to streamline processes, enhance trust, and unlock new possibilities. In this blog post, we will explore how our blockchain services can empower your organization and shape the future of digital transactions.<br/><br/><br/>

                            <b>Blockchain</b> Transforming Industries with Transparency and Security:<br/><br/>
                            Blockchain is a distributed ledger technology that enables secure and transparent transactions without the need for intermediaries. Our team of blockchain experts specializes in developing custom blockchain solutions that ensure trust, immutability, and efficiency in various industries. Whether it's supply chain management, financial transactions, or decentralized applications, we leverage the power of blockchain to drive innovation and redefine traditional processes.<br/><br/><br/>

                            <b>Decentralization and Trust</b> Empowering Digital Transactions:<br/><br/>
                            Blockchain's decentralized nature eliminates the need for a central authority, enabling peer-to-peer transactions with increased security and trust. Our blockchain solutions leverage consensus mechanisms, such as Proof-of-Work or Proof-of-Stake, to validate transactions and maintain the integrity of the network. By utilizing smart contracts, we automate and enforce agreements, ensuring transparency, accuracy, and efficiency in your digital transactions. Blockchain empowers organizations to eliminate intermediaries, reduce costs, and enhance the security and integrity of their operations.<br/><br/><br/>

                            <b>Supply Chain Management</b> Ensuring Traceability and Authenticity:<br/><br/>
                            Blockchain has the potential to revolutionize supply chain management by providing end-to-end visibility and traceability. Our blockchain solutions enable organizations to track and authenticate products at every stage of the supply chain, ensuring transparency and reducing the risk of counterfeit goods. By recording transactions, verifying certifications, and tracking inventory, we help you build trust with customers, partners, and regulators, ensuring compliance and fostering a more efficient and reliable supply chain ecosystem.<br/><br/><br/>

                            <b>Smart Contracts and Decentralized Applications</b> Enabling Automation and Innovation:<br/><br/>
                            Blockchain's programmable feature allows the development of smart contracts and decentralized applications (DApps), opening new doors for automation and innovation. Our expertise in blockchain development includes building smart contracts that automate business processes, facilitate self-executing agreements, and enable decentralized governance. Whether it's creating a decentralized marketplace, building a blockchain-based voting system, or developing a tokenized economy, we help you leverage the power of smart contracts and DApps to drive efficiency, transparency, and user empowerment.<br/><br/><br/>

                            <b>Security and Data Privacy</b> Protecting Digital Assets:<br/><br/>
                            Blockchain's cryptographic techniques provide robust security and protect sensitive data. Our blockchain solutions incorporate encryption and cryptographic protocols to secure transactions, identities, and sensitive information. By utilizing private and permissioned blockchain networks, we ensure that your data is accessible only to authorized participants while maintaining the benefits of transparency and accountability.<br/><br/><br/>

                            <b>Our Approach to Blockchain Development:</b><br/><br/>
                            At Welcome NoCode, we understand that each organization has unique blockchain requirements and objectives. Our blockchain development process starts with a comprehensive analysis of your business needs, industry landscape, and desired outcomes. We collaborate closely with your team to define the blockchain solution's architecture, consensus mechanism, data structure, and security considerations. Throughout the development cycle, we emphasize regular communication, iterative development, and rigorous testing to ensure that our blockchain solution meets your expectations and delivers tangible value.<br/><br/><br/>
                        </TabPanel>
                        <TabPanel value={8} sx={{ p: 2, maxWidth: "70%" }}>
                            At <b>Welcome NoCode</b>, we are passionate about harnessing the transformative power of robotics to build intelligent and autonomous solutions that revolutionize industries, enhance efficiency, and unlock new possibilities. With our expertise in robotics development, we enable organizations to automate processes, enhance productivity, and drive innovation. In this blog post, we will explore how our robotics services can empower your organization and shape the future of automation.<br/><br/><br/>
                            Robotics combines the fields of mechanical engineering, electronics, and artificial intelligence to create machines that can perform tasks autonomously or in collaboration with humans. Our team of robotics experts specializes in developing customized robotic solutions that enhance operational efficiency, improve safety, and drive innovation in various industries. Whether it's manufacturing, healthcare, or logistics, we leverage the power of robotics to streamline processes and redefine traditional workflows.<br/><br/><br/>

                            <b>Intelligent Automation</b> Enhancing Efficiency and Productivity:<br/><br/>
                            Intelligent automation is at the core of robotics, enabling organizations to automate repetitive tasks, optimize operations, and enhance overall efficiency. Our robotics solutions leverage advanced sensors, computer vision, and machine learning algorithms to enable robots to perceive their environment, make intelligent decisions, and adapt to changing conditions. By implementing robotics technologies, we help businesses reduce human error, increase productivity, and reallocate human resources to more value-added activities.<br/><br/><br/>

                            <b>Collaborative Robotics</b> Enhancing Human-Machine Interaction:<br/><br/>
                            Collaborative robots, also known as cobots, work alongside humans, enhancing productivity and safety in various industries. Our robotics solutions include designing and implementing cobots that can perform tasks in close collaboration with human workers. These cobots are equipped with advanced sensing capabilities, ensuring safe interaction with humans and enabling seamless collaboration in shared workspaces. By implementing collaborative robotics, we help organizations enhance productivity, optimize workflow efficiency, and improve worker satisfaction.<br/><br/><br/>

                            <b>Industry-Specific Robotics</b> Tailoring Solutions to Your Needs:<br/><br/>
                            We understand that different industries have unique requirements and challenges. Our robotics development approach involves a comprehensive analysis of your specific needs, operational workflows, and desired outcomes. We collaborate closely with your team to design and develop robotic solutions that address your industry-specific requirements. Whether it's automating assembly lines, performing complex surgeries, or optimizing warehouse operations, we tailor our robotics solutions to suit your precise needs, enhancing your competitive advantage and driving innovation.<br/><br/><br/>

                            <b>Safety and Compliance</b> Prioritizing Robust Standards:<br/><br/>
                            Safety is a critical consideration in robotics development. We adhere to stringent safety standards and implement measures to ensure the safe operation of robots in various environments. Our robotics solutions incorporate safety features such as collision detection, emergency stop systems, and risk assessment mechanisms. We also prioritize compliance with industry regulations to ensure that our robotics solutions meet the necessary standards, fostering trust and confidence in their implementation.<br/><br/><br/>

                            <b>Our Approach to Robotics Development</b><br/><br/>
                            At Welcome NoCode, we understand that each organization has unique robotics requirements and objectives. Our robotics development process starts with a comprehensive analysis of your business needs, operational workflows, and desired outcomes. We collaborate closely with your team to define the robotics solution's functionalities, design aesthetics, and integration requirements. Throughout the development cycle, we emphasize regular communication, iterative development, and rigorous testing to ensure that our robotics solution meets your expectations and delivers tangible value.<br/><br/><br/>
                        </TabPanel>
                    </Tabs>
                </div>
                <DeliverInfo/>
                <div className='OurGoal'>
                    <div className="OurGoalTitle">Our main goal is to help you achieve yours.</div>
                    <div className="GoalText">
                        <div className="GoalTextTitle">We are a team of passionate individuals who are masters in their respective fields. We bring a wealth of knowledge and experience to the table, and our sole purpose is to guide and empower you on your journey.</div>
                        <div className="GoalTextContent">
                            <div className="GoalTextContentTitle">From strategic planning and innovative solutions to personalized consultations and ongoing support, we are committed to being your trusted advisors every step of the way.</div>
                            <div className="GoalTextContentText">
                                We believe in the power of innovation and continuously strive to stay ahead of the curve. In a rapidly changing world, we embrace cutting-edge technologies and industry best practices to ensure that our clients are equipped with the latest tools and strategies for success. Your goals are our driving force, and we are relentless in our pursuit of excellence.
                            </div>
                        </div>
                        <div className="GoalTextContent">
                            <div className="GoalTextContentTitle">Transparency, integrity, and client satisfaction are the cornerstones of our business. We value open communication and actively listen to your needs, challenges, and aspirations. By truly understanding your unique circumstances, we can tailor our solutions to provide the best possible outcomes for you.</div>
                            <div className="GoalTextContentText">
                                No matter how big or small your dreams may be, we are here to support you at every stage of your journey. Together, we will break barriers, overcome challenges, and transform your aspirations into reality. When you succeed, we succeed.
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )
}

export default Software
