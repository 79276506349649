import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import './RightMenu.css'

// type Anchor = 'top' | 'left' | 'bottom' | 'right'

export default function RightMenu() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer =
    (anchor, open) =>
    (event) => {
      if (
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

  const openPage = (url) => {
    window.location.href = url
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="CategoryTitle">Industries</div>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Software'} onClick={() => openPage('/software-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Artificial intelligence'} onClick={() => openPage('/artificial-intelligence-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Business services'} onClick={() => openPage('/business-services-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Cybersecurity'} onClick={() => openPage('/cybersecurity-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Data services'} onClick={() => openPage('/data-services-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Gaming'} onClick={() => openPage('/gaming-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Hardware and equipment'} onClick={() => openPage('/hardware-and-equipment-industry')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Robotics'} onClick={() => openPage('/robotics-industry')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <div className="CategoryTitle">Services</div>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Engineering'} onClick={() => openPage('/engineering-services')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Service and maintenance'} onClick={() => openPage('/service-and-maintenance-services')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <div className="CategoryTitle">Products</div>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Code vacations'} onClick={() => openPage('/code-vacations')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Cron jobs expert'} onClick={() => openPage('/cron-jobs-expert')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Check-in shop'} onClick={() => openPage('/check-in-shop')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Database migration cheap'} onClick={() => openPage('/database-migration-cheap')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <div className="CategoryTitle">Company</div>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Contact us'} onClick={() => openPage('/contact-us')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'About us'} onClick={() => openPage('/about-us')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Use cases'} onClick={() => openPage('/use-cases')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'Job vacancies'} onClick={() => openPage('/job-vacancies')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary={'News and events'} onClick={() => openPage('/news-and-events')} />
          </ListItemButton>
        </ListItem>
      </List>
      <br/>
      <br/>
      <br/>
    </Box>
  )

  return (
    <div>
      <React.Fragment key='right'>
          <Button 
            style={{
                backgroundColor: 'black',
                color: '#fff',
                marginLeft: '20px',
                fontFamily: 'PoppinsMedium'
            }}
           onClick={toggleDrawer('right', true)}
           >
            Open Menu
          </Button>
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            {list('right')}
          </Drawer>
        </React.Fragment>
    </div>
  )
}