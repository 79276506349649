import './CheckInShop.css'
import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

function CheckInShop() {
    return (
        <div className="CheckInShop">
            <Header/>
            <Footer/>
        </div>
    )
}

export default CheckInShop