import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Landing from './views/Landing/Landing'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import TermsAndConditions from './views/TermsAndConditions/TermsAndConditions'
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy'
import RefundPolicy from './views/RefundPolicy/RefundPolicy'
import Software from './views/Industries/Software/Software'
import ArtificialIntelligence from './views/Industries/ArtificialIntelligence/ArtificialIntelligence'
import BusinessServices from './views/Industries/BusinessServices/BusinessServices'
import Cybersecurity from './views/Industries/Cybersecurity/Cybersecurity'
import DataServices from './views/Industries/DataServices/DataServices'
import EducationAndTraining from './views/Industries/EducationAndTraining/EducationAndTraining'
import Gaming from './views/Industries/Gaming/Gaming'
import HardwareAndEquipment from './views/Industries/HardwareAndEquipment/HardwareAndEquipment'
import Robotics from './views/Industries/Robotics/Robotics'
import AboutUs from './views/Company/AboutUs/AboutUs'
import ContactUs from './views/Company/ContactUs/ContactUs'
import JobVacancies from './views/Company/JobVacancies/JobVacancies'
import NewsAndEvents from './views/Company/NewsAndEvents/NewsAndEvents'
import UseCases from './views/Company/UseCases/UseCases'
import Engineering from './views/Services/Engineering/Engineering'
import ServiceAndMaintenance from './views/Services/ServiceAndMaintenance/ServiceAndMaintenance'
import CodeVacations from './views/Products/CodeVacations/CodeVacations'
import CronJobsExpert from './views/Products/CronJobsExpert/CronJobsExpert'
import CheckInShop from './views/Products/CheckInShop/CheckInShop'
import DatabaseMigration from './views/Products/DatabaseMigration/DatabaseMigration'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/software-industry" element={<Software/>} />
          <Route path="/artificial-intelligence-industry" element={<ArtificialIntelligence/>} />
          <Route path="/business-services-industry" element={<BusinessServices/>} />
          <Route path="/cybersecurity-industry" element={<Cybersecurity/>} />
          <Route path="/data-services-industry" element={<DataServices/>} />
          <Route path="/gaming-industry" element={<ArtificialIntelligence/>} />
          <Route path="/hardware-and-equipment-industry" element={<Gaming/>} />
          <Route path="/robotics-industry" element={<Robotics/>} />
          <Route path="/engineering-services" element={<Engineering/>} />
          <Route path="/service-and-maintenance-services" element={<ServiceAndMaintenance/>} />
          <Route path="/code-vacations" element={<CodeVacations/>} />
          <Route path="/cron-jobs-expert" element={<CronJobsExpert/>} />
          <Route path="/check-in-shop" element={<CheckInShop/>} />
          <Route path="/database-migration-cheap" element={<DatabaseMigration/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/use-cases" element={<UseCases/>} />
          <Route path="/job-vacancies" element={<JobVacancies/>} />
          <Route path="/news-and-events" element={<NewsAndEvents/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
