import './RefundPolicy.css'

function RefundPolicy() {
    const app = window.location.search.split('?app=')[1];
    
    let AppName = '';
    let AppUrl = '';
    
    if (app === 'codevacations') {
        AppName = 'Code Vacations';
        AppUrl = 'https://www.code.vacations';
    } else if (app === 'checkinshop') {
        AppName = 'Check In Shop';
        AppUrl = 'https://www.check-in.shop';
    } else if (app === 'cronjobsexpert') {
        AppName = 'Cron Jobs Expert';
        AppUrl = 'https://www.cronjobs.expert';
    } else {
        AppName = 'Welcome NoCode';
        AppUrl = 'https://www.welcomenocode.com';
    }
    
    if (AppName && AppUrl) {
        return (
            <div className="RefundPolicy">
                <img style={{width: '100px', height: '100px'}} src='https://www.welcomenocode.com/static/media/logo.dedcc2152587762d00a8890b8bebce7b.svg' />
                
                <h1><strong>RETURN AND EXCHANGE POLICY</strong></h1>

                Last Updated: 3/26/2023


                <p>Thank you for shopping at <strong>{AppUrl}</strong></p>
                <p>If, for any reason, You are not completely satisfied with a purchase, we invite You to review our Policy. </p>
                <p>The following terms are applicable for any products that you purchased with us.</p><br/>

                

                <h2><strong>INTERPRETATION AND DEFINITIONS</strong></h2>
                <pre> </pre>
                <h3><strong>Interpretation</strong></h3>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h3><strong>Definitions</strong></h3>
                <p>For the purposes of this Return and Exchange Policy:</p>

                <p><strong>Business Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to <strong>{AppName}</strong> Located at <strong>Lviv, Zelena 204</strong>, <strong>UA</strong>.</p>

                <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
                <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
                <p><strong>Service</strong> refers to the Website.</p>
                <p><strong>Website</strong> refers to My Site, accessible from <strong>https://www.code.vacations/</strong></p>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

                

                <br/><h2><strong>GENERAL RETURN POLICY</strong></h2>

                <p>Products that are bought from the <strong>Physical Stores can’t be returned. </strong>All Items are eligible for return exempt the products that are: <strong>Services with discount</strong></p>

                <p>You are always required to ship back the items by following the shipping instruction:</p>
                <p><strong>Send your a receipt by email to daniel@code.vacations</strong></p>

                

                <br/><h2><strong>REFUND POLICY</strong></h2>

                <p>In order to be eligible for a refund, you have to return the goods within <strong>30</strong> days of your purchase. If the product is damaged in any way, or you have initiated the return after <strong>30</strong> days have passed, you will not be eligible for a refund.</p>

                <ul>
                <li><strong>Product must have the receipt or proof of purchase</strong></li>
                </ul>

                <p>Aside from these conditions there are items that can be returned but are not refundable as stated in the following:</p>
                <p><strong></strong></p>

                <p>If the product you have received which turns out to be defective or otherwise of poor quality (save for direct warranty products which are discussed below), please notify us as soon as reasonably possible after you become aware of the defect or poor quality.</p>

                <p>After we receive your product, our team of professionals will inspect it and process your refund. The money will be refunded to the <strong>Original Payment Method</strong> you’ve used during the purchase. If the payment was made thru bank the following information are needed to be provided:</p>
                <p><strong>Full name and IBAN number</strong></p>

                <p>Unfortunately, product that were marked as a gift when purchased is <strong>not allowed</strong> to be <strong>Returned or Exchanged.</strong></p>

                <h3><strong>HOW TO INITIATE A RETURN</strong></h3>
                <p>If you have a request for Return, Refund or Exchange and if you have further clarification and questions, Please do not hesitate to contact us through our:</p>

                <p>Email Address: <span style={{color: "#000080"}}><strong>daniel@welcomenocode.com</strong></span></p>

                <p>You will be updated for their Return Status through their<strong> EMAIL,</strong> provided that contact information is recorded to us.</p>
            </div>
        )
    } else {
        return (
            <>
                <p>
                    <h1 style={{marginLeft: 20}}>
                        Refund Policy Not Found 404
                    </h1>
                </p>
            </>
        )
    }
  }
  
  export default RefundPolicy