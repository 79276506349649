import React, { useState } from 'react'
import './Accordion.css'

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    if (activeIndex === index) {
        setActiveIndex(null);
    } else {
        setActiveIndex(index);
    }
  };

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const itemClassName = isActive ? 'AccordionItem active' : 'AccordionItem';

    return (
      <React.Fragment key={index}>
        <div className={itemClassName}>
          <div className="AccordionTitle" onClick={() => onItemClick(index)}><span>{item.title}</span> <div className={isActive ? 'AccordionIcon active' : 'AccordionIcon rotate-minus'}>{isActive ? '-' : '+'}</div></div>
          {isActive && <div className="AccordionContent">{item.content}</div>}
        </div>
      </React.Fragment>
    );
  });

  return <div className="accordion">{renderedItems}</div>;
};

export default Accordion;
