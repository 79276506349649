import React from 'react'
import './DeliverInfo.css'
import CircleNumber from '../CircleNumber/CircleNumber'
import RequestForm from '../RequestForm/RequestForm'

const DeliverInfo = () => {
  return (
    <div className="DeliverInfo">
        <div className="DeliverTitle">
            We aim to deliver faster and better.
        </div>
        <div className="DeliverySteps">
            <div className="Stepper">
                <div className="Step">
                    <CircleNumber number="1"/>
                    <div className="StepInfo">
                        <div className="StepName">Send a request and get an offer</div>
                        <div className="StepDesc">We are experts in advanced software development - we provide you an offer based on the specifications you give us.</div>
                    </div>
                </div>
                <div className="Step">
                    <CircleNumber number="2"/>
                    <div className="StepInfo">
                        <div className="StepName">Production starts</div>
                        <div className="StepDesc">A project manager will be responsible for executing the project according to the given specifications. The technical department function as an important support during the execution of the project. Our highly skilled workers perform the actual product.</div>
                    </div>
                </div>
                <div className="Step">
                    <CircleNumber number="3"/>
                    <div className="StepInfo">
                        <div className="StepName">The delivery will be developed and hosted on the cloud</div>
                        <div className="StepDesc">As the development is completed, we thoroughly check and review the quality in accordance to our customers requirements. Afterwards, we deploy the production, together with the agreed documentation.</div>
                    </div>
                </div>
            </div>
            <RequestForm/>
        </div>
    </div>
  );
};

export default DeliverInfo;
