import './Landing.css'
import React, { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Button from '@mui/material/Button'
import Accordion from '../../components/Accordion/Accordion'
import softwareIcon from '../../assets/landing-software-min.jpeg'
import aiIcon from '../../assets/landing-ai-min.jpeg'
import businessIcon from '../../assets/landing-business-min.jpeg'
import cybersecurityIcon from '../../assets/landing-cybersecurity-min.jpeg'
import dataServicesIcon from '../../assets/landing-data-services-min.jpeg'
import gameIcon from '../../assets/landing-game-min.jpeg'
import hardwareIcon from '../../assets/landing-hardware-min.jpeg'
import roboticsIcon from '../../assets/landing-robotics.avif'
import RequestForm from '../../components/RequestForm/RequestForm'
import DeliverInfo from '../../components/DeliverInfo/DeliverInfo'
import local from '../../assets/local.json'

function Landing() {
    const [isRequestModalOpen, setRequestModalOpen] = useState(false)

    const openRequestModal = () => {
        setRequestModalOpen(true)
    }

    const closeRequestModal = () => {
        setRequestModalOpen(false)
    }

    return (
        <div className="Landing">
            { isRequestModalOpen && 
                <div className="RequestModal">
                    <RequestForm close={() => closeRequestModal()}/>
                </div>
            }
            <Header/>
            <div className="Banner">
                <video autoPlay loop muted playsInline src="https://assets.mixkit.co/videos/preview/mixkit-busy-office-space-918-large.mp4"></video>
                <div className="BannerTitle">We work with software.<br/>With us, anything is possible.</div>
                <div className="BannerSubtitle">Software is one of the most versatile tools you can work with. It empowers us to tackle a wide range of challenges, providing solutions that are efficient, scalable, and adaptable. With a commitment to sustainability, we strive to develop software that not only solves problems but also takes responsibility for the future.</div>
                <div className="BannerWidget clutch-widget" data-url="https://widget.clutch.co" data-widget-type="13" data-height="50" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2183877"></div>
                <Button 
                    id="BannerBtn"
                    onClick={() => openRequestModal()}
                    variant="outlined"
                >
                    Send Request
                </Button>
            </div>
            <div className="AboutOurView">
                <div className="ViewTitle">Pay less - Get more</div>
                <div className="OurView">Harnessing years of software expertise, we empower important industries with cutting-edge no-code and AI tools. Our solutions deliver unmatched speed, efficiency, and quality, revolutionizing how businesses thrive in the digital era.</div>
                <div className="IndustriesList">
                    <div className="IndustryItem" style={{backgroundImage: `url(${softwareIcon})`}}>
                        <div className="IndustryName">Software</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${aiIcon})`}}>
                        <div className="IndustryName">Artificial intelligence</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${businessIcon})`}}>
                        <div className="IndustryName">Business services</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${cybersecurityIcon})`}}>
                        <div className="IndustryName">Cybersecurity</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${dataServicesIcon})`}}>
                        <div className="IndustryName">Data services</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${gameIcon})`}}>
                        <div className="IndustryName">Gaming</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${hardwareIcon})`}}>
                        <div className="IndustryName">Hardware</div>
                    </div>
                    <div className="IndustryItem" style={{backgroundImage: `url(${roboticsIcon})`}}>
                        <div className="IndustryName">Robotics</div>
                    </div>
                </div>
            </div> 
            <DeliverInfo/>
            <div className="Questions">
                <div className="QuestionsTitle">Frequently asked questions.</div>
                <div className="QuestionsBlocks">
                    <div className="BlockColumn">
                        <span className="BlockTitle">Production</span>
                        <Accordion items={local["en"].landingProductionAccordion}/>
                    </div>
                    <div className="BlockColumn">
                        <span className="BlockTitle">In general</span>
                        <Accordion items={local["en"].landingGeneralAccordion}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Landing
