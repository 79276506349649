import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import './RequestForm.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const RequestForm = ({ close }) => {
  const [email, setEmail] = useState(null)
  const [projectDetails, setProjectDetails] = useState(null)
  const [reference, setReference] = useState(null)

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendForm = async () => {
    if (!validateEmail(email)) {
      return toast.warning('E-mail is not valid', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }

    const formMessage = `🙍‍♂️ Client _${email} is asking about: ${projectDetails}. His ref: ${reference}`
    await axios.get('https://api.telegram.org/bot' + process.env.REACT_APP_TELEGRAM_TOKEN + '/sendMessage?chat_id=' + process.env.REACT_APP_TELEGRAM_CHAT_ID + '&parse_mode=HTML&text=' + formMessage)
    if (close) close()
    setEmail(null)
    setProjectDetails(null)
    setReference(null)
    document.getElementById('FI1').value = ''
    document.getElementById('FI2').value = ''
    document.getElementById('FI3').value = ''
    toast.success('Requset is sent!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    })
  }

  return (
    <div className="RequestForm">
        { close && <div className="XIcon"><img onClick={() => close()} src="https://logowik.com/content/uploads/images/close1437.jpg"/></div>}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <span className="FormUnderTitle">Get a good offer from us. 👋</span>
        <span className="FormTitle">Send a request.</span>
        <span className="FormSubtitle">🔒 All uploads are secure and confidential.</span>
        <input className="FormInput" id="FI1" placeholder="E-mail address" value={email} onChange={(e) => setEmail(e.target.value)}/>
        <textarea className="FormInput" id="FI2" placeholder="Enter project details" value={projectDetails} onChange={(e) => setProjectDetails(e.target.value)}/>
        <textarea className="FormInput" id="FI3" placeholder="How did you hear about us?" value={reference} onChange={(e) => setReference(e.target.value)}/>
        <Button 
            id="RequestFormBtn"
            onClick={() => sendForm()}
            variant="outlined"
        >
            Get an offer
        </Button>
    </div>
  );
};

export default RequestForm;
