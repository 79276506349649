import React from 'react';
import './CircleNumber.css'

const CircleNumber = ({ number }) => {
  return (
    <div className="Circle">
      <span className="CircleNumber">{number}</span>
    </div>
  );
};

export default CircleNumber;
