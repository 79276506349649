import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import './Header.css'
import logo from '../../assets/logo.svg'
import RightMenu from '../../components/RightMenu/RightMenu'
import RequestForm from '../../components/RequestForm/RequestForm'

function Header() {
    const [isRequestModalOpen, setRequestModalOpen] = useState(false)

    const openPage = (url) => {
        window.location.href = url
    }

    const openRequestModal = () => {
        setRequestModalOpen(true)
    }

    const closeRequestModal = () => {
        setRequestModalOpen(false)
    }

    return (
        <div className="Header">
            { isRequestModalOpen && 
                <div className="RequestModal">
                    <RequestForm close={() => closeRequestModal()}/>
                </div>
            }
            <nav>
                <div className="MenuAndLogo" onClick={() => openPage('/')}>
                    <img className="Logo" src={logo}/>
                    <span className="CompanyName">Welcome NoCode</span>
                </div>
                <div className="RightMenuBtn">
                    <RightMenu/>
                </div>
                <div className="Pages">
                    <div className="IndustriesDropdown">
                        <div className="IndustriesDropdownBtn">Industries <img className="DropdownMark" src="https://global-uploads.webflow.com/5ffd7ee38610c70c9cd867df/5ffd7ee3d65e280a6f92fa3c_dropdown-icon.svg" alt="Services Mark"></img></div>
                        <div className="IndustriesDropdownContent">
                            <a href="/software-industry">Software</a>
                            <a href="/artificial-intelligence-industry">Artificial intelligence</a>
                            <a href="/business-services-industry">Business services</a>
                            <a href="/cybersecurity-industry">Cybersecurity</a>
                            <a href="/data-services-industry">Data services</a>
                            <a href="/gaming-industry">Gaming</a>
                            <a href="/hardware-and-equipment-industry">Hardware and equipment</a>
                            <a href="/robotics-industry">Robotics</a>
                        </div>
                    </div>
                    <div className="ServicesDropdown">
                        <div className="ServicesDropdownBtn">Services <img className="DropdownMark" src="https://global-uploads.webflow.com/5ffd7ee38610c70c9cd867df/5ffd7ee3d65e280a6f92fa3c_dropdown-icon.svg" alt="Services Mark"></img></div>
                        <div className="ServicesDropdownContent">
                            <a href="/engineering-services">Engineering</a>
                            <a href="/service-and-maintenance-services">Service and maintenance</a>
                        </div>
                    </div>
                    <div className="ProductsDropdown">
                        <div className="ProductsDropdownBtn">Products <img className="DropdownMark" src="https://global-uploads.webflow.com/5ffd7ee38610c70c9cd867df/5ffd7ee3d65e280a6f92fa3c_dropdown-icon.svg" alt="Products Mark"></img></div>
                        <div className="ProductsDropdownContent">
                            <a href="/code-vacations">Code vacations</a>
                            <a href="/cron-jobs-expert">Cron jobs expert</a>
                            <a href="/check-in-shop">Check-in shop</a>
                            <a href="/database-migration-cheap">Database migration cheap</a>
                        </div>
                    </div>
                    <div className="CompanyDropdown">
                        <div className="CompanyDropdownBtn">Company <img className="DropdownMark" src="https://global-uploads.webflow.com/5ffd7ee38610c70c9cd867df/5ffd7ee3d65e280a6f92fa3c_dropdown-icon.svg" alt="Company Mark"></img></div>
                        <div className="CompanyDropdownContent">
                            <a href="/contact-us">Contact us</a>
                            <a href="/about-us">About us</a>
                            <a href="/use-cases">Use cases</a>
                            <a href="/job-vacancies">Job vacancies</a>
                            <a href="/news-and-events">News and events</a>
                        </div>
                    </div>
                    <Button 
                        style={{
                            backgroundColor: 'black',
                            color: '#fff',
                            marginLeft: '20px',
                            fontFamily: 'PoppinsMedium'
                        }}
                        onClick={() => openRequestModal()}
                        variant="outlined"
                    >
                        <img className="RequestMark" src="https://global-uploads.webflow.com/5ffd7ee38610c70c9cd867df/5ffd7ee3d65e28cdfa92fa87_quote-icon.svg" alt="Company Mark"></img> Request
                    </Button>
                </div>
            </nav>
        </div>
    )
}

export default Header
