import './Footer.css'
import logo from '../../assets/logo.svg'

import React, { useState, useEffect } from 'react'

function Footer() {
  const openPage = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div className="Footer">
      <div className="FLeftPart">
        <div className="Company">
          <div className="Info">
            <img className="Logo" src={logo}/>
            <div className="Name">Welcome NoCode</div>
          </div>
          <div className="Desc">
            We're a product and outsourcing company with one as one team. We deliver to customers in all industries.
          </div>
          <div className="SocialNetworks">
            <div id="instagramLink" onClick={() => openPage('https://www.instagram.com/welcomenocode')}/>
            <div id="linkedinLink" onClick={() => openPage('https://www.linkedin.com/company/welcomenocode')}/>
            <div id="facebookLink" onClick={() => openPage('https://www.facebook.com/WelcomeNoCode')}/>
            <div id="youtubeLink" onClick={() => openPage('https://www.youtube.com/channel/UC8qH5sM5whcelUJjIk7RjUA')}/>
            <div id="threadsLink" onClick={() => openPage('https://www.threads.net/@welcomenocode')}/>
          </div>
        </div>
        <div className="Copyright">Copyright {new Date().getFullYear()} - Welcome NoCode 🇺🇦</div>
      </div>
      <div className="FRightPart">
        <div className="Categories">
          <div className="CategoryColumn">
            <div className="CategoryName">Industries</div>
            <a className="CategoryItem" href="/software-industry">Software</a>
            <a className="CategoryItem" href="/artificial-intelligence-industry">Artificial intelligence</a>
            <a className="CategoryItem" href="/business-services-industry">Business services</a>
            <a className="CategoryItem" href="/cybersecurity-industry">Cybersecurity</a>
            <a className="CategoryItem" href="/data-services-industry">Data services</a>
            <a className="CategoryItem" href="/gaming-industry">Gaming</a>
            <a className="CategoryItem" href="/hardware-and-equipment-industry">Hardware and equipment</a>
            <a className="CategoryItem" href="/robotics-industry">Robotics</a>
          </div>
          <div className="CategoryColumn">
            <div className="CategoryName">Services</div>
            <a className="CategoryItem" href="/engineering-services">Engineering</a>
            <a className="CategoryItem" href="/service-and-maintenance-services">Service and maintenance</a>
          </div>
          <div className="CategoryColumn">
            <div className="CategoryName">The company</div>
            <a className="CategoryItem" href="/contact-us">Contact us</a>
            <a className="CategoryItem" href="/about-us">About us</a>
            <a className="CategoryItem" href="/use-cases">Use cases</a>
            <a className="CategoryItem" href="/job-vacancies">Job vacancies</a>
            <a className="CategoryItem" href="/news-and-events">News and events</a>
          </div>
        </div>
        <div className="Privacies">
          <a className="PrivaciesLink" href="/privacy-policy">Privacy policy</a>
        </div>
      </div>
    </div>
  )
}

export default Footer
