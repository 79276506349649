import './JobVacancies.css'
import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

function JobVacancies() {
    useEffect(() => {
        window.open('https://t.me/welcomenocode', '_self')
    }, [])

    return (
        <div className="JobVacancies">
            <Header/>
            <Footer/>
        </div>
    )
}

export default JobVacancies